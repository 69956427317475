/* our custom styles */

// DELETE ME:
.custom-theme-marker {
    display: none;
    position: absolute;
    top: 300px;
    left: 30%;
    margin: auto 0;
    transform: rotate(-20deg);
    background: #00ECFF;
    color: #fff;
    padding: 1em;
    z-index: 100;
}
.section-front-page .custom-theme-marker{
    display: block;
}


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.color_gray {
    color: #a59e9e;
}

/* CITTA Conference Theme */

@media (min-width: 1200px) {
    .container {width: 1000px;}
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #4d4d4d;
    background-color: #fafafa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: justify;
    line-height: 1.8em;
}

a {
    color: #8c2c18;
    text-decoration: none;
    background: transparent;
}

h1.documentFirstHeading {
    padding-bottom: 9px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid rgb(204, 204, 204);
    font-size: 26px;
    color: #ff5e00;
}

div#parent-fieldname-text > h3 {
    /* border-bottom: 1px solid rgb(204, 204, 204); */
    text-decoration: underline #cccccc;
    margin-bottom: 0.5em;
    }
    
div#parent-fieldname-text > h4 {
/*    border-bottom: 1px solid rgb(204, 204, 204); */
text-decoration: underline #cccccc;
margin: 20px 0 0.5em 0;
}

h5 {
    padding: 20px 0 5px;
    text-decoration: underline #cccccc;
}

div.documentDescription.description {
    font-size: 12px;
    color: rgb(105, 105, 105);
    margin-bottom: 10px;
    font-style: italic;
}

.image-left.speaker {
    width: 100px;
}

p.speaker {
    margin: 30px 0 0;
}

td.speaker_image {
    width: 120px;
}

td.speaker_text {
    width: auto;
    text-align: justify;
    font-size: 14px;
}

.invisible-grid {
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    background: transparent;
}

table > tbody > tr > td, table > tfoot > tr > td {
   border: 0px solid rgba(241, 238, 238, 0.952);
    font-size: 12px;
    text-align: center;
    width: 200px;
}

table {
    width: 100%;
    max-width: 100%;
    border: 0px solid #fafafa;
    background-color: #fafafa;
}

table.committee {
    width: 540px;
}

table.committee2015 {
    width: 85%;
}

table.scommittee2018 {
    width:400px;
}

table.scommittee {
    width: 85%;
}

table.sponsors {
    border: 0px solid rgb(250, 250, 250);
    font-size: 12px;
    text-align: center;
    background: transparent;
    width: 150px;
    margin-left: 25px;    
}

table.sponsors td {
    padding-top: 10px;
}

table.invisible-grid.programme td {
    text-align: left;
}

td.time {
    text-align: left;
    width: 10%;
}

td.keynote {
    text-align: left;
    width: 20%;
}

.portlet {
    border: 1px solid #e5e5e5;
    margin-bottom: 20px;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    text-align: center;
}

.portlet .portletHeader {
    background: #f2f1f1;
    font-size: 13px;
    padding: 5px 5px;
    color: #696969;
}

div.portletStaticText.portlet-static-registration {
    text-align: center;
}

fieldset {
  padding: 10px 10px 10px;
  min-width: max-content;
  border: 1px solid #c0c0c0;
}

legend {
  display: block;
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #4d4d4d;
  border: 0;
  border-bottom: 0;
  font-weight: 300;
}

.responsive-img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }
  
  img {
    max-width: 100%;
  }

  h5 {
      padding: 20px 0 30px;
  }

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
}

aside#portal-footer-signature.portlet.portletClassic {
    display: none;
}

#portal-footer-wrapper p {
    border-bottom: none;
    padding-bottom: 0;
}

#portal-footer-wrapper {
    padding: 20px 15px;
    background-color: #2e3133;
    color: #cccccc;
    text-align: center;
    clear: both;
}

img.logo {
    width: 1070px;
    float: left;
}

/* Login and Searchbox configuration */

#portal-anontools li {
    margin: 5px 0 12px 12px;
}

#portal-header #portal-searchbox .searchSection {
    display: none;
}

#portal-header #portal-searchbox form > div > * {
    font-size: 14px;
    height: 25px;
}

.principal {
    background: #ffffff;
    background-size: 20px 20px;
    /* margin-top: -20px; */
    /* margin-bottom: 20px; */
    padding: 30px 0;
}

div#hero.principal {
    height: 50px;
    padding-top: 5px;
}

div.LSBox {
    margin-right: 5px;
    height: 30px;
}

div#portal-searchbox {
    float: right;
    margin-left: 0.5em;
    z-index: 2;
}

div#portal-anontools {
    width: 100px;
    float: right;
}

div.searchSection {
    display: none;
}

input.searchButton {
    display: none;
}

input[type="text"], input[type="password"], textarea, select {
    display: block;
    width: 100%;
    height: 25px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #696969;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.pat-livesearch .livesearch-results {
    display: none;
    position: absolute;
    background-color: #fafafa;
    z-index: 100;
    border-right: 1px solid rgb(144, 44, 24);
    border-bottom: 1px solid #8c2c18;
    border-left: 1px solid #8c2c18;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(140,44,24,.075), 0 0 8px rgba(140,44,24,0.6);
    box-shadow: inset 0 1px 1px rgba(140,44,24, .075), 0 0 8px rgba(140,44,24,0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(140,44,24,.075),0 0 8px rgba(140,44,24,0.6);
    margin: 0;
    padding: 0;
    list-style: none;
}

input[type="text"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
    border-color: #8c2c18;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(140,44,24,.075), 0 0 8px rgba(140, 44, 24,0.6);
}

li.search-result > h4 {
    font-size: 13px;
}

/* Plone nav */

.plone-nav > li > a {
    position: relative;
    display: block;
    padding: 10px 15px;
    color: white;
    font-size: 15px;
}

.plone-navbar-nav li .submenu {
    width: 220px;
}

.plone-navbar-toggle {
    float: right;
    width: 55px;
    margin-right: 20px;
    padding-top: 5px;
}

.plone-navbar-toggle:hover {
    background-color: #8c2c18;
    float: right;
    width: 55px;
    margin-right: 20px;
}

.navbar {
    position: relative;
    min-height: 40px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    background-color: #8c2c18;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #bdb8b8;
}

.plone-navbar-nav > .selected > a, .plone-navbar-nav > .selected > a:hover, .plone-navbar-nav > .selected > a:focus {
    color: white;
    background-color: #bdb8b8;
}


/* webcouturier.dropdownmenu */

.plone-navbar-nav li {
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top:   (@plone-font-size-base * .25) dashed;
      border-right: (@plone-font-size-base * .25) solid transparent;
      border-left:  (@plone-font-size-base * .25) solid transparent;
    }
    &:hover > .submenu {
      display: block;
    }
    &.active:after,
    &:hover:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      width: 100%;
      z-index: 999;
    }
    .submenu {
      background-color: #8c2c18;
      display: none;
      list-style: none;
      padding-left: 0;
      padding-bottom: 0;
      position: absolute;
      z-index: 9999;
      .opener {
        background: transparent;
        float: right;
        text-align: right;
        width: @plone-font-size-base * 2;
        height: @plone-font-size-base * 2;
        margin-top: -(@plone-font-size-base * 0.4);
        margin-right: -(@plone-font-size-base * 0.7);
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: bottom;
          border-left:   (@plone-font-size-base * .25) dashed;
          border-bottom: (@plone-font-size-base * .25) solid transparent;
          border-top:  (@plone-font-size-base * .25) solid transparent;
        }
      }
      a {
        color: white;
        display: block;
        line-height: 20px;
        min-width: 14em;
        padding: 10px 15px 10px 15px;
        position: relative;
        &:hover {
          background-color: #bdb8b8;
          text-decoration: none;
        }
        &:before {
          // margin-right: 10px;
        }
        .submenu_image {
          float: right;
        }
      }
      // .dropdown-submenu a.hasDropDown {
      //   &:after {
      //     content: "▸";
      //     display: inline-block;
      //     position: absolute;
      //     right: 0;
      //   }
      // }
      li {
        position: relative;
        float: left;
        width: 100%;
        &:hover ul {
          top: 0;
          left: 100%;
          width: 100%
        }
      }
    }
    &:last-child .submenu li:hover ul {
      left: -100%;
    }
  }
  
  @media (max-width: 768px) {
    .plone-navbar-collapse.in {
      .opener {
        background: transparent;
        border: 1px solid white;
        border-radius: (@plone-font-size-base * .25);
        float: right;
        text-align: center;
        width: @plone-font-size-base * 2;
        height: @plone-font-size-base * 2;
        margin-top: -(@plone-font-size-base * 0.4);
        margin-right: -(@plone-font-size-base * 0.7);
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 0;
          margin-bottom: 0.2em;
          vertical-align: bottom;
          border-top:    0.5em dashed;
          border-right:  0.5em solid transparent;
          border-left:   0.5em solid transparent;
          border-bottom: 0;
        }
      }
      .menu-open { display: block!important; }
      .plone-navbar-nav {
          margin: 0 -15px 0;
      }
      li {
        &:hover > .submenu {
          display: none;
        }
        .submenu {
          .opener {
            background: transparent;
            border: 1px solid white;
            border-radius: (@plone-font-size-base * .25);
            float: right;
            text-align: center;
            width: @plone-font-size-base * 2;
            height: @plone-font-size-base * 2;
            margin-top: -(@plone-font-size-base * 0.4);
            margin-right: -(@plone-font-size-base * 0.7);
            .caret {
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 0;
              margin-bottom: 0.2em;
              vertical-align: bottom;
              border-top:    0.5em dashed;
              border-right:  0.5em solid transparent;
              border-left:   0.5em solid transparent;
              border-bottom: 0;
            }
          }
          // display: block;
          position: relative;
          a {
            &:hover {
              // background-color: unset;
            }
          }
          li {
            float: none;
            &:hover ul {
                top: unset;
                left: unset;
            }
          }
        }
        .opener.menu-open {
          > .caret {
            border-top:    0;
            border-right:  0.5em solid transparent;
            border-left:   0.5em solid transparent;
            border-bottom: 0.5em dashed;
          }
        }
        .navTreeLevel0 .submenu_title {
          padding-left: 0.5em;
        }
        .navTreeLevel1 .submenu_title {
          padding-left: 1em;
        }
        .navTreeLevel2 .submenu_title {
          padding-left: 1.5em;
        }
        .navTreeLevel3 .submenu_title {
          padding-left: 2em;
        }
      }
      .plone-navbar-nav li {
        .submenu {
          // display: none;
        }
        &:hover > .submenu {
          display: unset;
        }
      }
    }
  }

/* footer */

#portal-footer-wrapper a {
    color: #ffffff;
}

/* Hide glyphicons */

i.glyphicon.link-external, i.glyphicon.link-https {
    display: none;
}

.portletStaticText.portlet-static-external-links {
    margin: 40px auto;
    width: 60%;
}